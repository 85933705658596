





























import { defineComponent } from "@vue/composition-api";

import AuthenticationBasePage from "@/components/AuthenticationBasePage.vue";
import CreateAccountForm from "@/components/authentication/CreateAccountForm.vue";

export default defineComponent({
  name: "Home",
  components: { AuthenticationBasePage, CreateAccountForm },
  setup(_, { root }) {
    const email = root.$route.query.email as string;
    const token = root.$route.query.token as string;

    return { email, token };
  }
});
